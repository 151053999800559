import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

import { Destination } from '../models/slots-batch.model';

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {

  private destinationsUrl = '/:apiBase/companies/:companyId/destinations';
  private destinationsbyId = this.destinationsUrl + '/:destinationId';

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number): Observable<Destination[]> {
    const url = this.destinationsUrl
      .replace(":companyId", companyId + '');

    const stream = this.http.get<Destination[]>(url);

    return stream.pipe(map(destinations => plainToInstance(Destination, destinations)));
  }

  public create(companyId: number, destination: Destination): Observable<Destination> {
    const url = this.destinationsUrl
      .replace(":companyId", companyId + '');

    const stream = this.http.post<Destination>(url, destination);

    return stream.pipe(map(response => plainToInstance(Destination, response)));
  }

  public edit(companyId: number, destination: Destination): Observable<Destination> {
    const url = this.destinationsbyId
      .replace(":companyId", companyId + '')
      .replace(":destinationId", destination.id + '');

    const stream = this.http.put<Destination>(url, destination);

    return stream.pipe(map(response => plainToInstance(Destination, response)));
  }
}
